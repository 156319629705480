<template>
  <form>
    <vl-properties-list>
      <vl-properties-label>{{ $t('sourceentity.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ detail.id }}</vl-properties-data>
    </vl-properties-list>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField name="code" :label="t('sourceentity.detail.code')" :placeholder="t('sourceentity.detail.code')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('sourceentity.detail.label')"
        :placeholder="t('sourceentity.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('sourceentity.detail.description')"
        :placeholder="t('sourceentity.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="model"
        :label="t('sourceentity.detail.model')"
        :placeholder="$t('sourceentity.detail.model')"
        :options="entityModels"
        :custom-label="entityModelLabel"
        :mod-loading="fetchingModels"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="crs"
        :label="t('sourceentity.detail.crs')"
        :placeholder="$t('sourceentity.detail.crs')"
        :options="sridTypes"
        :custom-label="(e) => e.name"
        :mod-loading="fetchingModels"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="dataConnection"
        :label="t('sourceentity.detail.dataConnection')"
        :placeholder="$t('sourceentity.detail.dataConnection')"
        :mod-loading="dataConnectionStore.loading"
        :options="dataConnections"
        :custom-label="(e) => e.label"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="schema"
        :label="t('sourceentity.detail.schema')"
        :placeholder="t('sourceentity.detail.schema')"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import {
  SourceEntityDetailDto,
  EnumerationDto,
  SearchEntityModelDto,
  DataConnectionDto,
} from '@/api/portal-api/clients';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useI18n } from 'vue-i18n';
import { useEntityModelSelectStore } from '@/modules/configuration/store/entity-model/entity-model-select.store';
import { ENUM_SridTypes } from '@/modules/configuration/common';
import { useSourceEntityStore } from '@/modules/configuration/store/source-entity/source-entity.store';
import { useDataconnectionStore } from '@/modules/configuration/store/dataconnection/dataconnection.store';
import { useProductProcessDefinitionDetailStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-detail.store';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import { ENUM_StatusTypes } from '../../../../common';

const { t } = useI18n();
const store = useSourceEntityStore();
const entityModelSelectStore = useEntityModelSelectStore();
const dataConnectionStore = useDataconnectionStore();
const props = defineProps<{
  detail: SourceEntityDetailDto;
  specificationId: number;
}>();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('sourceentity.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('sourceentity.detail.label'), length: 256 })),
  code: string()
    .required(t('validations.messages.required', { _field_: t('sourceentity.detail.code') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('sourceentity.detail.code'), length: 256 })),
  description: string().required(t('validations.messages.required', { _field_: t('sourceentity.detail.description') })),
  crs: object().required(t('validations.messages.required', { _field_: t('sourceentity.detail.crs') })),
  model: object().required(t('validations.messages.required', { _field_: t('sourceentity.detail.model') })),
  dataConnection: object().required(
    t('validations.messages.required', { _field_: t('sourceentity.detail.dataConnection') }),
  ),
  schema: string().required(t('validations.messages.required', { _field_: t('sourceentity.detail.schema') })),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const detail = computed((): SourceEntityDetailDto => {
  return props.detail;
});

const sridTypes = computed((): EnumerationDto[] => {
  return ENUM_SridTypes;
});

const fetchingModels = computed((): Boolean => {
  return entityModelSelectStore.loading;
});

const entityModels = computed((): SearchEntityModelDto[] => {
  return entityModelSelectStore.items ?? [];
});

const dataConnections = computed((): DataConnectionDto[] => {
  return dataConnectionStore.items ?? [];
});

onMounted(async () => {
  await dataConnectionStore.get();
  await entityModelSelectStore.getModels({ force: true, contextTypeName: 'Source' });

  resetForm({
    values: {
      schema: detail.value.schema,
      dataConnection: detail.value.dataConnection,
      label: detail.value.label,
      code: detail.value.code,
      description: detail.value.description,
      crs: detail.value.crs,
      model: detail.value.model,
    },
  });
});

const onSuccess = async (values) => {
  await store.update(props.specificationId, props.detail.id, {
    code: values.code,
    label: values.label,
    description: values.description,
    modelId: values.model.id,
    crs: values.crs.name,
    schema: values.schema,
    dataConnectionId: values.dataConnection.id,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const entityModelLabel = (entityModel: SearchEntityModelDto) => {
  let status = '';

  if(entityModel.statusId !== ENUM_StatusTypes.find((x) => x.name === 'Actief')?.id) {
    status = ` [${ENUM_StatusTypes.find((x) => x.id === entityModel.statusId)?.name}]`;
  }

  return `[${entityModel.id}] ${entityModel.code} (${entityModel.label})${status}`;
};

defineExpose({ onSubmit });
</script>