<template>
  <form>
    <vl-properties-list v-vl-spacer:bottom.small>
      <vl-properties-label>{{ $t('deliveryspecification.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    </vl-properties-list>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('deliveryspecification.detail.label')"
        :placeholder="t('deliveryspecification.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('deliveryspecification.detail.description')"
        :placeholder="t('deliveryspecification.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="tolerance"
        type="number"
        step="0.0001"
        :label="t('deliveryspecification.detail.tolerance')"
        :placeholder="t('deliveryspecification.detail.tolerance')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="schema"
        :label="t('deliveryspecification.detail.scheme')"
        :placeholder="t('deliveryspecification.detail.schema')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="hasExactPrecision" :label="t('deliveryspecification.detail.hasExactPrecision')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="skipImport" :label="t('deliveryspecification.detail.skipImport')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="skipCheck" :label="t('deliveryspecification.detail.skipCheck')" />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { DeliverySpecificationDetailDto } from '@/api/portal-api/clients';
import { useDeliverySpecificationStore } from '@/modules/configuration/store/delivery-specification/delivery-specification.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { boolean, number, object, string } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';

const store = useDeliverySpecificationStore();

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  detail: DeliverySpecificationDetailDto;
}>();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('deliveryspecification.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('deliveryspecification.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('deliveryspecification.detail.description') }),
  ),
  schema: string().required(t('validations.messages.required', { _field_: t('deliveryspecification.detail.schema') })),
  tolerance: number().required(
    t('validations.messages.required', { _field_: t('deliveryspecification.detail.tolerance') }),
  ),
  hasExactPrecision: boolean(),
  skipImport: boolean(),
  skipCheck: boolean(),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(() => {
  resetForm({ values: props.detail });
});

const onSuccess = async (values) => {
  await store.update(definitionId.value, props.detail.id, {
    ...values,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
