<template>
  <NotFound v-if="notFound" />
  <vl-region v-if="loading">
    <div v-vl-align:center>
      <vl-loader :message="$t('general.pageLoading')" />
    </div>
  </vl-region>
  <vl-region v-if="product && !loading">
    <vl-layout>
      <vl-grid  v-vl-spacer:bottom.medium>
        <vl-column width="9" width-xs="12">
          <vl-alert
            v-if="alert.visible"
            :icon="alert.icon"
            :mod-error="alert.isError"
            :mod-success="alert.isSuccess"
            closable
            :close-text="$t('product.detail.alert.closeText')"
            :title="alert.title"
            :content="alert.content"
            @close="closeAlert"
          />
        </vl-column>
        <vl-column width="9" width-xs="12">
          <vl-title tag-name="h1">{{ $t('product.detail.title') }}</vl-title>
        </vl-column>
        <vl-column>
          <vl-properties>
            <vl-properties-column>
              <vl-properties-list>
                <vl-properties-label>{{ $t('product.detail.specification.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.specification && product.specification.label }}</vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.status.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.status }}</vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.isDryRun.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.isDryRun ? $t('general.yes') : $t('general.no')}}</vl-properties-data>
                <vl-properties-label v-if="product.statusId == 2">{{
                  $t('product.detail.reason.label')
                }}</vl-properties-label>
                <vl-properties-data v-if="product.statusId == 2">{{ product.failReason }}</vl-properties-data>
                <!-- <vl-properties-label>{{ $t('product.detail.organisation.label') }}</vl-properties-label>
                <vl-properties-data></vl-properties-data> -->
                <!-- <vl-properties-label>{{ $t('product.detail.product.label') }}</vl-properties-label>
                <vl-properties-data></vl-properties-data> -->
              </vl-properties-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
      </vl-grid>
      <vl-grid  v-vl-spacer:bottom.medium>
        <vl-column>
          <vl-properties>
            <vl-properties-column mod-full>
              <vl-title tag-name="h3"> {{ $t('product.detail.metadata.title') }} </vl-title>
              <vl-properties-list>
                <vl-properties-label>{{ $t('product.detail.metadata.uuid.label') }}</vl-properties-label>
                <vl-properties-data
                  ><a :href="product.metadata && product.metadata.metadataCenterURL" class="vl-link" target="_blank">
                    {{ product.metadata && product.metadata.uuid }}
                  </a></vl-properties-data
                >
                <vl-properties-label>{{ $t('product.detail.metadata.versionDate.label') }}</vl-properties-label>
                <vl-properties-data>{{ dateFilter(product.metadata?.versionDate) }}</vl-properties-data>
                <vl-properties-label>{{ $t('product.detail.metadata.temporalLimitation.label') }}</vl-properties-label>
                <vl-properties-data>
                  {{ dateFilter(product.metadata?.temporalLimitationFrom) }} -
                  {{
                    dateFilter(product.metadata?.temporalLimitationTo, $t('product.detail.noEndDate.label'))
                  }}</vl-properties-data
                >
                <vl-properties-label>{{ $t('product.detail.metadata.versionDescription.label') }}</vl-properties-label>
                <vl-properties-data>{{ product.metadata && product.metadata.versionDescription }}</vl-properties-data>
              </vl-properties-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
      </vl-grid>
      <vl-grid>
        <vl-column>
          <vl-properties>
            <vl-properties-column v-if="product.changeHistory && product.changeHistory.length" mod-full>
              <vl-title tag-name="h3"> {{ $t('product.detail.changeHistory.title') }} </vl-title>
              <vl-accordion-list v-if="product.changeHistory && product.changeHistory.length">
                <vl-accordion-list-item v-for="(entityChange, i) in product.changeHistory" :id="i" :key="i">
                  <vl-accordion :id="i.toString()" :title="getLabel(entityChange)" :open="product.changeHistory.length > 1 ? false: true">
                    <vl-properties-list >
                      <vl-properties-label class="vl-properties-label-custom">{{ $t('product.detail.changeHistory.currentCopies.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.currentCopies }}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom">{{ $t('product.detail.changeHistory.previousCopies.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.previousCopies }}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom">{{ $t('product.detail.changeHistory.newCopies.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.newCopies }} {{getPercentage(entityChange.newCopies, entityChange.previousCopies)}}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom">{{ $t('product.detail.changeHistory.changedCopies.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.changedCopies }} {{getPercentage(entityChange.changedCopies, entityChange.previousCopies)}}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom-padded">{{ $t('product.detail.changeHistory.attributeChanges.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.attributeChanges }}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom-padded">{{ $t('product.detail.changeHistory.geometryChanges.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.geometryChanges }}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom-padded">{{ $t('product.detail.changeHistory.attributeAndGeometryChanges.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.attributeAndGeometryChanges }}</vl-properties-data>
                      <vl-properties-label class="vl-properties-label-custom">{{ $t('product.detail.changeHistory.abolishedCopies.label') }}</vl-properties-label>
                      <vl-properties-data class="vl-properties-data-custom">{{ entityChange.abolishedCopies }} {{getPercentage(entityChange.abolishedCopies, entityChange.previousCopies)}}</vl-properties-data>
                    </vl-properties-list>
                    </vl-accordion>
                </vl-accordion-list-item>
              </vl-accordion-list>
            </vl-properties-column>
          </vl-properties>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import { ProductDetailService, ProductDetailServiceInstance } from './ProductDetail.service';
import { ProductChangeHistory } from '@/api';
import { useRoute, useRouter } from 'vue-router';
import { dateFilter } from '@/filters';
import { useI18n } from 'vue-i18n';
import NotFound from '@/core/security/NotFound.vue';

export default defineComponent({
  components: {
    NotFound,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const productService: ProductDetailService = ProductDetailServiceInstance;

    // lifecycle hooks
    onMounted(async () => {
      await productService.init(parseInt(route.params.id.toString(), 10));
      if (route.query.created === 'true') {
        alertSuccess(
          t('product.detail.created.alertSuccess.content').toString(),
          t('product.detail.created.alertSuccess.title').toString(),
        );
      }
    });
    onUnmounted(() => {
      productService.reset();
    });

    // reactive data
    const data = reactive({
      alert: {
        visible: false,
        isError: false,
        isSuccess: false,
        title: '',
        content: '',
        icon: ''
      }
    });

    const product = computed(() => productService.state.product);
    const notFound = computed(() => productService.state.notFound);
    const loading = computed(() => productService.state.loading);

    const getLabel = (item: ProductChangeHistory): string => {
      return `Entiteit ${item.productEntityCode} - ${item.productEntityLabel}`;
    };

    const getPercentage = (numerator: number, denominator: number): string => {
      return `(${denominator > 0 ? Math.round(numerator/denominator*100) : 0}%)`;
    };

    const alertError = (message: string, title: string) => {
      data.alert = {
        visible: true,
        isError: true,
        isSuccess: false,
        icon: 'alert-circle',
        title: title,
        content: message,
      };
    };

    const alertSuccess = (message: string, title: string) => {
      data.alert = {
        visible: true,
        isError: false,
        isSuccess: true,
        icon: 'check',
        title: title,
        content: message,
      };
    };

    const closeAlert = () => {
      data.alert = {
        visible: false,
        isError: false,
        isSuccess: false,
        icon: '',
        title: '',
        content: '',
      };
    };

    return {
      ...toRefs(data),
      product,
      productService,
      dateFilter,
      notFound,
      loading,
      getLabel,
      getPercentage,
      closeAlert
    };
  },
});
</script>

<style lang="scss" scoped>

.vl-properties-label-custom {
  flex: 0 0 30%; max-width: none;
}

.vl-properties-label-custom-padded {
  @extend .vl-properties-label-custom;
  padding-left: 5%;
}

.vl-properties-data-custom {
  flex: 0 0 70%;
}
</style>