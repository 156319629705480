<template>
  <vl-multiselect
    v-bind="$attrs"
    :id="id"
    ref="multiselect"
    :model-value="data.value"
    :v-model="data.value"
    :mod-multiple="false"
    :allowEmpty="true"
    :options="data.options"
    :internalSearch="false"
    :customLabel="customLabel"
    type="search"
    :track-by="keySelector || 'id'"
    mod-block
    @update:model-value="handleUpdateModelValue"
    @select="handleSelect"
    @remove="handleRemove"
    @search-change="handleSearchChange"
  />
</template>

<script lang="ts" setup>
import { reactive, useAttrs, watch, onMounted } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['update:modelValue', 'search-change', 'select', 'remove']);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Object],
    default: '',
    required: false,
  },
  keySelector: {
    type: String,
    required: false,
    default: '',
  },
  customLabel: {
    type: Function,
    required: true,
    default: (option: any) => option.name,
  },
});

const data = reactive({
  value: null as any,
  options: [],
});

const sortOptions = (options: any[]): any[] => {
  if (options) {
    return options.sort((a, b) => getCustomLabel(a).localeCompare(getCustomLabel(b)));
  }
  return [];
};

const filterOptions = (search: string): any[] => {
  return props.options.filter(
    (option) => !search || getCustomLabel(option).toLowerCase().includes(search.toLowerCase()),
  );
};

const getCustomLabel = (option: any): string => {
  return props.customLabel(option) || option.name || '';
};

const handleSearchChange = (search: string) => {
  data.options = sortOptions(filterOptions(search));
  emit('search-change', search);
};

const handleUpdateModelValue = (value: any) => {
  data.value = value;
  // If keySelector is provided, use the keySelector to get the model value
  const modelValue = props.keySelector ? value?.[props.keySelector] : value;
  emit('update:modelValue', modelValue);
};

const handleSelect = (value: any) => {
  emit('select', value);
};

const handleRemove = (value: any) => {
  emit('remove', value);
};

onMounted(() => {
  data.options = sortOptions(props.options);
});

const setInternalValue = (value: any) => {
  // if keySelector is provided, the value is compared with the option value using the keySelector
  // else, the value is compared by the option id property
  data.value = value
    ? props.options?.find((option) => {
        if (props.keySelector) {
          return option[props.keySelector] === value;
        } else {
          return option['id'] === value['id'];
        }
      })
    : null;
};

watch(
  () => props.modelValue,
  (value) => setInternalValue(value),
);

watch(
  () => props.options,
  (newValue) => {
    data.options = sortOptions(newValue);
    if (props.modelValue) {
      setInternalValue(props.modelValue);
    }
  },
);

useAttrs();
</script>
