import { EnvironmentConfig } from '@/common/models/environment';
import local from './environments/local.json';
import dev from './environments/dev.json';
import tst from './environments/tst.json';
import bet from './environments/bet.json';
import prd from './environments/prd.json';

async function fetchEnvironment(): Promise<string> {
    const environmentResponse = await fetch(import.meta.env.BASE_URL + 'environment.json');
    const environmentJson = await environmentResponse.json();
    return environmentJson.env;
}

function selectConfig(environment: string): EnvironmentConfig {
    switch (environment) {
        case 'local':
            return buildEnvironment(local);
        case 'dev':
            return buildEnvironment(dev);
        case 'tst':
            return buildEnvironment(tst);
        case 'bet':
            return buildEnvironment(bet);
        case 'prd':
            return buildEnvironment(prd);
        default:
            return buildEnvironment(prd);
    }
}

let config: EnvironmentConfig = buildEnvironment(local);

function buildEnvironment(config: any): EnvironmentConfig {
    return Object.assign({}, config, {
        version: import.meta.env.PACKAGE_VERSION,
    });
}

export async function loadEnvironmentConfig(): Promise<EnvironmentConfig> {
    const environment = await fetchEnvironment();
    config = selectConfig(environment);
    return config;
}

export function isFeatureEnabled(key: FeatureKeys): boolean {
    return config.features[key];
}

export type FeatureKeys = keyof typeof config.features;

export { config };
