<template>
  <vl-form-message-label v-if="label" :for="name">
    {{ label }}
  </vl-form-message-label>
  <Field v-slot="{ handleChange, value, errors }" :name="name">
    <PbsSelect
      v-bind="$attrs"
      :id="name"
      :model-value="value"
      :v-model="value"
      :mod-error="!!errors.length"
      :options="options"
      :customLabel="customLabel"
      track-by="id"
      @update:model-value="handleChange"
      @select="handleChange"
      @remove="handleChange(null)"
    />
    <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
  </Field>
</template>

<script lang="ts" setup>
import { Field } from 'vee-validate';
import { useAttrs } from 'vue';
import PbsSelect from '@/components/forms/PbsSelect.vue';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['search-change']);

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
  customLabel: {
    type: Function,
    required: true,
    default: (option: any) => option.name,
  },
});

useAttrs();
</script>
