<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium class="pbs-form-group" mod-stacked-large>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="id">{{ $t('entitymodel.list.filter.id.label') }}</vl-form-message-label>
        <vl-input-field
          id="id"
          v-model="filter.id"
          name="id"
          type="number"
          :placeholder="$t('entitymodel.list.filter.id.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="code">{{ $t('entitymodel.list.filter.code.label') }}</vl-form-message-label>
        <vl-input-field
          id="code"
          v-model="filter.code"
          name="code"
          type="search"
          :placeholder="$t('entitymodel.list.filter.code.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="label">{{ $t('entitymodel.list.filter.label.label') }}</vl-form-message-label>
        <vl-input-field
          id="label"
          v-model="filter.label"
          name="label"
          type="search"
          :placeholder="$t('entitymodel.list.filter.label.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="description">{{
          $t('entitymodel.list.filter.description.label')
        }}</vl-form-message-label>
        <vl-input-field
          id="description"
          v-model="filter.description"
          name="description"
          type="search"
          :placeholder="$t('entitymodel.list.filter.description.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="contextId">{{
          $t('entitymodel.list.filter.contextId.label')
        }}</vl-form-message-label>
        <PbsSelect
          id="contextId"
          v-model="filter.contextType"
          key-selector="name"
          :placeholder="$t('vl-select.placeholder')"
          :options="contextTypes"
          :custom-label="(e) => e.name"
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="entityId">{{ $t('entitymodel.list.filter.entityId.label') }}</vl-form-message-label>
        <PbsSelect
          id="entityId"
          v-model="filter.entityType"
          key-selector="name"
          :placeholder="$t('vl-select.placeholder')"
          :options="entityTypes"
          :custom-label="(e) => e.name"
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label>{{ $t('entitymodel.list.filter.hasHistory.label') }}</vl-form-message-label>
        <vl-radio
          id="hasHistory_1"
          v-model="filter.hasHistory"
          name="hasHistory_1"
          :value="true"
          type="radio"
          mod-block
          @click="radioClicked(true)"
          >{{ $t('general.yes') }}</vl-radio
        >
        <vl-radio
          id="hasHistory_0"
          v-model="filter.hasHistory"
          :value="false"
          name="hasHistory_0"
          type="radio"
          @click="radioClicked(false)"
          >{{ $t('general.no') }}</vl-radio
        >
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="statusId">{{ $t('entitymodel.list.filter.status.label') }}</vl-form-message-label>
        <PbsSelect
          id="statusId"
          v-model="filter.statusType"
          key-selector="name"
          :placeholder="$t('vl-select.placeholder')"
          :options="statusTypes"
          :custom-label="(e) => e.name"
        />
      </vl-column>

      <vl-column width="9" width-s="9" width-xs="12">
        <vl-action-group>
          <vl-button :mod-loading="loading" @click="search({ resetPager: true })">{{
            $t('entitymodel.list.filter.search')
          }}</vl-button>
          <vl-button mod-secondary :mod-loading="loading" @click="clear">{{
            $t('entitymodel.list.filter.clear')
          }}</vl-button>
        </vl-action-group>
      </vl-column>
      <vl-column width="3" width-s="3" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button mod-primary @click="openImportModal">{{ $t('entitymodel.list.filter.import') }}</vl-button>
          <vl-button mod-secondary @click="create">{{ $t('entitymodel.list.filter.create') }}</vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid>
      <vl-data-table-extended
        v-if="items"
        :data="items"
        :columns="columnMap"
        :fetching="loading"
        :meta="metaData"
        mod-clickable-rows
        mod-zebra
        @column-clicked="columnClicked"
        @pager-clicked="pagerClicked"
      >
        <template v-if="!loading" v-slot:noresults>
          <div v-vl-align:left>
            <div class="vl-u-spacer--large">
              <vl-title tag-name="h2">{{ $t('entitymodel.list.table.noResults.title') }}</vl-title>
              <p>{{ $t('entitymodel.list.table.noResults.subtitle') }}</p>
            </div>
          </div>
        </template>
      </vl-data-table-extended>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { Ref, computed, ref, h } from 'vue';
import { ModelListFilter, useEntityModelListStore } from '../../store/entity-model/entity-model-list.store';
import { onMounted, onUnmounted } from 'vue';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';
import { EnumerationDto, SearchEntityModelDto } from '@/api/portal-api/clients';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useAlertStore, IAlert } from '@/modules/core/store/alert.store';
import { ENUM_ContextTypes, ENUM_EntityTypes, ENUM_StatusTypes } from '@/modules/configuration/common';
import { VlActionGroup } from '@govflanders/vl-ui-design-system-vue3';
import EntityModelActionButton from './subcomponents/entity-model-action-button/EntityModelActionButton.vue';
import PbsSelect from '@/components/forms/PbsSelect.vue';
import EntityModelImportForm from './subcomponents/entity-model-import-form/EntityModelImportForm.vue';
import { useModalStore } from '@/modules/core/store/modal.store';

const store = useEntityModelListStore();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const filter = computed((): ModelListFilter => {
  return store.filter ?? {};
});

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): SearchEntityModelDto & { routerLink?: string }[] => {
  return store.items?.map((i) => {
    return {
      ...i,
      routerLink: `/configuration/entitymodel/${i.id}`,
    };
  });
});

const metaData = computed(() => {
  return store.metaData;
});

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'id',
      header: t('entitymodel.list.table.header.id'),
      enableSorting: true,
      size: 5,
      link: true,
    },
    {
      accessorKey: 'code',
      header: t('entitymodel.list.table.header.code'),
      enableSorting: true,
      size: 20,
      link: true,
    },
    {
      accessorKey: 'label',
      header: t('entitymodel.list.table.header.label'),
      enableSorting: true,
      sortDescFirst: false,
      size: 30,
      link: true,
    },
    {
      accessorKey: 'contextTypeName',
      header: t('entitymodel.list.table.header.contextType'),
      enableSorting: true,
      size: 20,
      link: true,
    },
    {
      accessorKey: 'entityTypeName',
      header: t('entitymodel.list.table.header.entityType'),
      enableSorting: true,
      size: 20,
      link: true,
    },
    {
      accessorKey: 'hasHistory',
      header: t('entitymodel.list.table.header.hasHistory'),
      enableSorting: true,
      size: 5,
      link: true,
      cell: (props) => {
        return props?.getValue() ? t('general.yes') : t('general.no');
      },
    },
    {
      accessorKey: 'statusId',
      header: t('entitymodel.list.table.header.status'),
      enableSorting: true,
      size: 20,
      link: true,
      cell: (props) => {
        const statusType = ENUM_StatusTypes.find((s) => s.id === props?.getValue());
        return statusType?.name || '-';
      },
    },
    {
      accessorKey: '',
      header: t('entitymodel.list.table.header.actions'),
      size: 10,
      cell: (props) => {
        const id = parseInt(props.row.getValue('id'), 10);
        return h(VlActionGroup, {}, () => [h(EntityModelActionButton, { id })]);
      },
    },
  ];
});

const contextTypes = computed((): EnumerationDto[] => {
  return ENUM_ContextTypes;
});

const entityTypes = computed((): EnumerationDto[] => {
  return ENUM_EntityTypes;
});

const statusTypes = computed((): EnumerationDto[] => {
  return ENUM_StatusTypes;
});

const alert = computed((): IAlert => {
  return useAlertStore().alert;
});

onMounted(() => {
  setTimeout(async () => {
    store.init(createFilterFromRoute());
    await search();
  }, 0);
});

onUnmounted(() => {
  store.clearFilter();
});

const clear = async () => {
  store.clearFilter();
  await search();
};

const search = async ({ resetPager }: { resetPager: boolean } = { resetPager: false }) => {
  if (resetPager) {
    store.resetPager();
  }

  await store.getModels();
  if (!alert.value) {
    updateRoute();
  }
};

const create = () => {
  router.push({ name: 'entitymodel.create' });
};

const openImportModal = () => {
  const modalStore = useModalStore();

  const modalContent = {
    title: t('entitymodel.actions.import.description'),
    component: EntityModelImportForm,
  };

  modalStore.showModal(modalContent);
};

const radioClicked = (checked) => {
  if (filter.value.hasHistory === checked) {
    filter.value.hasHistory = null;
  } else filter.value.hasHistory = checked;
};

const createFilterFromRoute = (): ModelListFilter => {
  const filter: ModelListFilter = {};

  Object.keys(route.query).forEach((k) => {
    filter[k] = encodeURIComponent(route.query[k] as string | number | boolean);
  });

  return filter;
};

const updateRoute = () => {
  const query = {};
  Object.keys(store.filter).forEach((k) => {
    query[k] = encodeURIComponent(store.filter[k]);
  });

  router.push({
    ...route,
    path: route.path,
    query,
  });
};

const pagerClicked = (pageNumber: number) => {
  store.updatePage(pageNumber);
  search();
};

const columnClicked = (evt: { key: string; direction: string }) => {
  store.updateSorting(evt.key, evt.direction);
  search({ resetPager: true });
};
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
.vl-grid {
  flex-grow: 1;
}
</style>
