<template>
  <form @submit="onSubmit">
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('productspecification.detail.label')"
        :placeholder="t('productspecification.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('productspecification.detail.description')"
        :placeholder="t('productspecification.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="dataConnection"
        :label="t('productspecification.detail.dataConnection')"
        :placeholder="$t('productspecification.detail.dataConnection')"
        :mod-loading="dataConnectionStore.loading"
        :options="dataConnections"
        :custom-label="(e) => e.label"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="schema"
        :label="t('productspecification.detail.schema')"
        :placeholder="t('productspecification.detail.schema')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="baseMetadataUuid"
        :label="t('productspecification.detail.baseMetadataUuid')"
        :placeholder="t('productspecification.detail.baseMetadataUuid')"
      />
    </vl-form-column>    
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField
        name="isMetadataContinuous"
        :label="$t('productspecification.detail.isMetadataContinuous')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="skipMapping" :label="t('productspecification.detail.skipMapping')" />
    </vl-form-column>
    <vl-action-group v-vl-spacer:bottom.small>
      <vl-button icon="save" :mod-loading="reloading" mod-icon-before type="submit">
        {{ $t('general.save') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeCreate">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </form>
</template>
<script lang="ts" setup>
import { useProductSpecificationStore } from '@/modules/configuration/store/product-specification/product-specification.store';
import { useProductProcessDefinitionEditStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { boolean, object, string } from 'yup';
import { useDataconnectionStore } from '@/modules/configuration/store/dataconnection/dataconnection.store';
import { DataConnectionDto, CreateProductSpecificationDto } from '@/api/portal-api/clients';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';

const store = useProductSpecificationStore();
const editStore = useProductProcessDefinitionEditStore();
const dataConnectionStore = useDataconnectionStore();

const { t } = useI18n();
const route = useRoute();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('productspecification.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('productspecification.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('productspecification.detail.description') }),
  ),
  dataConnection: object().required(
    t('validations.messages.required', { _field_: t('productspecification.detail.dataConnection') }),
  ),
  schema: string().required(t('validations.messages.required', { _field_: t('productspecification.detail.schema') })),
  baseMetadataUuid: string()
    .max(256, t('validations.messages.maxChar', { _field_: t('productspecification.detail.baseMetadataUuid'), length: 256 })),
  isMetadataContinuous: boolean(),
  skipMapping: boolean()
});

const { handleSubmit } = useForm({
  validationSchema,
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const dataConnections = computed((): DataConnectionDto[] => {
  return dataConnectionStore.items ?? [];
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

onMounted(async () => {
  await dataConnectionStore.get();
});

const onSuccess = async (values) => {
  const createDto: CreateProductSpecificationDto = {
    productProcessDefinitionId: definitionId.value,
    label: values.label,
    description: values.description,
    dataConnectionId: values.dataConnection.id,
    schema: values.schema,
    baseMetadataUuid: values.baseMetadataUuid,
    isMetadataContinuous: values.isMetadataContinuous,
    skipMapping: values.skipMapping
  };

  await store.create(definitionId.value, createDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);
</script>

<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
</style>
