import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
    DeliveryRunClient,
    DeliveryProcessDefinitionClient,
    ProductProcessDefinitionClient,
    ProductSpecificationClient,
    IdentityClient,
    OrganisationClient,
    ProductRunClient,
    SupplierClient,
} from './clients';
import { createInterceptors } from './axios-interceptors';

// Created here so we can add the correlationId to every call we makee.
export function createInstance(): AxiosInstance {
    const axiosInstance = axios.create({
        transformResponse: [],
        headers: {
            'X-Correlation-Id': uuidv4(),
        },
    });
    createInterceptors(axiosInstance);
    return axiosInstance;
}

const isoDatePattern = new RegExp(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/);

export function dateReviver(key: string, value: any): unknown {
    if (typeof value === 'string' && value.match(isoDatePattern)) {
        return new Date(value); // isostring, so cast to js date
    }
    return value;
}

export const clients = {
    DeliveryRunClient: new DeliveryRunClient('', createInstance(), dateReviver),
    IdentityClient: new IdentityClient('', createInstance(), dateReviver),
    DeliveryProcessDefinitionClient: new DeliveryProcessDefinitionClient('', createInstance(), dateReviver),
    ProductProcessDefinitionClient: new ProductProcessDefinitionClient('', createInstance(), dateReviver),
    ProductSpecificationClient: new ProductSpecificationClient('', createInstance(), dateReviver),
    ProductRunClient: new ProductRunClient('', createInstance(), dateReviver),
    OrganisationClient: new OrganisationClient('', createInstance(), dateReviver),
    SupplierClient: new SupplierClient('', createInstance(), dateReviver),
};

export * from './clients';
