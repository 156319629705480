<template>
  <vl-properties-list v-vl-spacer:bottom.small>
    <vl-properties-label>{{ $t('productspecification.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('productspecification.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('productspecification.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('productspecification.detail.dataConnection') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.dataConnection ? props.detail.dataConnection?.code : `/` }}</vl-properties-data>
    <vl-properties-label>{{ $t('productspecification.detail.schema') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.schema }}</vl-properties-data>
    <vl-properties-label>{{ $t('productspecification.detail.baseMetadataUuid') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.baseMetadataUuid }}</vl-properties-data>
    <vl-properties-label>{{ $t('productspecification.detail.isMetadataContinuous') }}</vl-properties-label>
    <vl-properties-data>{{
      props.detail.isMetadataContinuous ? $t('general.yes') : $t('general.no')
    }}</vl-properties-data>
     <vl-properties-label>{{ $t('productspecification.detail.skipMapping') }}</vl-properties-label>
    <vl-properties-data>{{
      props.detail.skipMapping ? $t('general.yes') : $t('general.no')
    }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { ProductSpecificationDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: ProductSpecificationDetailDto;
}>();
</script>
