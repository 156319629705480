<template>
  <div>
    <vl-region>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-grid mod-stacked mod-left>
              <vl-column width="9" width-xs="12">
                <vl-title tag-name="h1">
                  {{ $t('product.list.title') }}
                </vl-title>
              </vl-column>
              <vl-column width="3" width-xs="12" class="vl-u-align-right">
                <vl-link mod-button mod-large to="/product/create">
                  {{ $t('product.list.action.create') }}
                </vl-link>
              </vl-column>
            </vl-grid>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
    <vl-region mod-stacked-large>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <div class="pbs-form-group">
              <vl-grid mod-stacked>
                <vl-column width="9" width-s="9" width-xs="12">
                  <vl-form-message-label for="specification" class="vl-search-filter__field__label">{{
                    $t('product.list.filter.specification.label')
                  }}</vl-form-message-label>
                  <PbsSelect
                    id="specification"
                    v-model="filter.specification"
                    key-selector="id"
                    :placeholder="$t('vl-select.placeholder')"
                    :options="specifications"
                    :custom-label="(e) => getDefinitionLabel(e.definition)"
                    @update:model-value="specificationChanged"
                  />
                </vl-column>
                <vl-column width="3" width-s="3" width-xs="12" />

                <vl-column width="6" width-s="6" width-xs="12">
                  <vl-form-message-label class="vl-search-filter__field__label">
                    {{ $t('product.list.filter.startExecution.label') }}</vl-form-message-label
                  >
                  <vl-grid>
                    <vl-column width="1" width-s="12">
                      <vl-form-message-label for="startExecutionFrom">Van</vl-form-message-label>
                    </vl-column>
                    <vl-column width="5" width-s="12">
                      <pbs-datepicker
                        id="startExecutionFrom"
                        :value="getDates(filter.startExecutionFrom)"
                        name="startExecutionFrom"
                        placeholder="Van"
                        @input="startExecutionFromChanged"
                      ></pbs-datepicker>
                    </vl-column>
                    <vl-column width="1" width-s="12">
                      <vl-form-message-label for="startExecutionTo">tot</vl-form-message-label>
                    </vl-column>
                    <vl-column width="5" width-s="12">
                      <pbs-datepicker
                        id="startExecutionTo"
                        :value="getDates(filter.startExecutionTo)"
                        name="startExecutionTo"
                        placeholder="Tot en met"
                        mod-inline
                        @input="startExecutionToChanged"
                      ></pbs-datepicker>
                    </vl-column>
                  </vl-grid>
                </vl-column>
                <vl-column width="3" width-s="6" width-xs="12">
                  <vl-form-message-label for="statusses" class="vl-search-filter__field__label">{{
                    $t('product.list.filter.status.label')
                  }}</vl-form-message-label>
                  <PbsSelect
                    id="statusses"
                    v-model="filter.status"
                    key-selector="id"
                    :placeholder="$t('vl-select.placeholder')"
                    :options="statusses"
                    :custom-label="(e) => e.name"
                    @update:model-value="statusChanged"
                  />
                </vl-column>
                <vl-column width="3" width-s="6" width-xs="12">
                  <vl-form-message-label for="isDryRun" class="vl-search-filter__field__label">{{
                    $t('product.list.filter.isDryRun.label')
                  }}</vl-form-message-label>
                  <vl-radio
                    id="isDryRun_1"
                    v-model="filter.isDryRun"
                    name="isDryRun_1"
                    :value="true"
                    type="radio"
                    mod-block
                    @click="isDryRunChanged(true)"
                    >{{ $t('general.yes') }}</vl-radio
                  >
                  <vl-radio
                    id="isDryRun_0"
                    v-model="filter.isDryRun"
                    :value="false"
                    name="isDryRun_0"
                    type="radio"
                    @click="isDryRunChanged(false)"
                    >{{ $t('general.no') }}</vl-radio
                  >
                </vl-column>
              </vl-grid>
            </div>
          </vl-column>
          <vl-column v-if="activeFilters && activeFilters.length">
            <vl-annotation v-vl-spacer:bottom.small mod-small>Actieve Filters:</vl-annotation>
            <div class="pbs-pill-list vl-u-display-flex vl-u-flex-wrap-wrap">
              <span v-for="activeFilter in activeFilters" :key="activeFilter.filter">
                <vl-pill
                  v-if="!!activeFilter.value"
                  v-vl-spacer:bottom.small
                  v-vl-spacer:right.small
                  mod-closable
                  @close="activeFilter.remove"
                  >{{ $t(activeFilter.filter) }}: {{ activeFilter.value }}</vl-pill
                >
              </span>
            </div>
            <span v-if="activeFilters.length">
              <vl-button type="reset" mod-secondary @click="clearFilters">Wis filters</vl-button>
            </span>
          </vl-column>
          <vl-column>
            <vl-title tag-name="h3">{{ totalcount }} {{ $t('product.list.results') }}</vl-title>
          </vl-column>
          <vl-column>
            <div class="vl-u-table-overflow">
              <vl-data-table mod-hover>
                <thead>
                  <tr>
                    <th scope="col">{{ $t('product.list.specification.label') }}</th>
                    <th scope="col">{{ $t('product.list.versionDescription.label') }}</th>
                    <th scope="col">{{ $t('product.list.startExecution.label') }}</th>
                    <th scope="col">{{ $t('product.list.endExecution.label') }}</th>
                    <th scope="col">{{ $t('product.list.status.label') }}</th>
                    <th style="width: 5%" scope="col">{{ $t('product.list.isDryRun.label') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in products" :key="product.id" @click.prevent="onHeaderClick(product)">
                    <td>{{ product.specification }}</td>
                    <td style="max-width: 20em">
                      {{ product.versionDescription }}
                    </td>
                    <td>{{ dateTimeFilter(product.startExecution) }}</td>
                    <td>{{ dateTimeFilter(product.endExecution) }}</td>
                    <td>{{ product.status }}</td>
                    <td>{{ product.isDryRun ? $t('general.yes') : $t('general.no') }}</td>
                    <!-- <td>
                      <div class="vl-u-align-right vl-u-whitespace--nowrap">
                        <router-link class="vl-link" :to="'' + product.id">{{
                          $t('product.list.detail.label')
                        }}</router-link>
                      </div>
                    </td> -->
                    <!-- <td>
                      <div class="vl-u-align-right">
                        <router-link
                          v-if="!product.hasMetadataGuid"
                          class="vl-link"
                          :to="'' + product.id + '/metadata'"
                          >{{ $t('product.list.addMetadata.label') }}</router-link
                        >
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </vl-data-table>
            </div>
          </vl-column>
          <vl-column>
            <vl-pager mod-align="right">
              <vl-pager-bounds
                :from="itemsFrom"
                :to="itemsTo"
                :total="itemsTotalcount"
                page-label="Page"
                :prefix="$t('pager.of')"
              />
              <vl-pager-item
                v-if="parseInt(itemsFrom) > 1"
                :a11yLabel="$t('pager.prev')"
                :label="$t('pager.prev')"
                type="previous"
                @click.prevent="previous"
              />
              <vl-pager-item
                v-if="itemsTo !== itemsTotalcount"
                :a11yLabel="$t('pager.next')"
                :label="$t('pager.next')"
                type="next"
                @click.prevent="next"
              />
            </vl-pager>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>

<script lang="ts">
import { ProductStatusType, ProductSpecification, ProductProcessDefinition, ProductSearchResult } from '@/api';
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { ProductListService, ProductListServiceInstance } from './ProductList.service';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { Dictionary } from '@/types';
import { dateFilter, dateTimeFilter } from '@/filters';
import { filterFromQueryString, sortingFromQueryString, pagingFromQueryString, getDates } from './ProductList.utils';
import { parseFormattedDateString } from '@/helpers';
import PbsDatepicker from '@/components/forms/PbsDatepicker.vue';
import PbsSelect from '@/components/forms/PbsSelect.vue';
import { getDefinitionLabel } from './common/';

export default defineComponent({
  components: {
    PbsDatepicker,
    PbsSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const listService: ProductListService = ProductListServiceInstance;

    // reactive data
    const data = reactive({
      loading: [],
    });

    // computed values
    const products = computed(() => listService.state.results || []);
    const specifications = computed(() => listService.specifications);
    const statusses = computed(() => listService.statusses);
    const filter = computed(() => listService.state.filter || {});
    const paging = computed(() => listService.state.paging || {});
    const sorting = computed(() => listService.state.sorting || {});
    const totalcount = computed(() => listService.state.totalcount || 0);
    const currentPage = computed(() => paging.value.pageNumber || 1);

    const totalPages = computed(() => {
      if (!totalcount.value || !paging.value.pageSize) {
        return 1;
      }
      return Math.floor(totalcount.value / paging.value.pageSize) + 1;
    });

    const itemsFrom = computed(() => {
      if (totalcount.value === 0) {
        return '0';
      }
      return ((currentPage.value - 1) * (paging.value.pageSize || 1) + 1).toString();
    });

    const itemsTo = computed(() => {
      const to = currentPage.value * (paging.value.pageSize || 1);
      if (totalcount.value < to) {
        return totalcount.value.toString();
      }
      return to.toString();
    });

    const itemsTotalcount = computed(() => (totalcount.value ? totalcount.value.toString() : '0'));

    const activeFilters = computed(() => {
      const activeFilters = [];

      for (const key in filter.value) {
        if ((filter.value as any)[key] === undefined) {
          continue;
        }

        const obj: { value?: string | null; filter?: string | null; remove?: () => void } = {};
        switch (key) {
          case 'specification':
            obj.value = specifications.value.find(
              (p: ProductSpecification) => p.id == filter.value.specification,
            )?.label;
            obj.filter = `product.list.filter.${key}.label`;
            obj.remove = (): void => {
              specificationChanged();
            };
            break;
          case 'startExecutionFrom':
            obj.value = dateFilter(filter.value.startExecutionFrom);
            obj.filter = `product.list.filter.${key}.label`;
            obj.remove = (): void => {
              startExecutionFromChanged();
            };
            break;
          case 'startExecutionTo':
            obj.value = dateFilter(filter.value.startExecutionTo);
            obj.filter = `product.list.filter.${key}.label`;
            obj.remove = (): void => {
              startExecutionToChanged();
            };
            break;
          case 'status':
            obj.value = statusses.value.find((p: ProductStatusType) => p.id == filter.value.status)?.name;
            obj.filter = `product.list.filter.${key}.label`;
            obj.remove = (): void => {
              statusChanged();
            };
            break;
          case 'isDryRun':
            obj.value = filter.value.isDryRun.toString();
            obj.filter = `product.list.filter.${key}.label`;
            obj.remove = (): void => {
              isDryRunChanged();
            };
            break;
          default:
            throw new Error(`Filter ${key} not found`);
        }

        activeFilters.push(obj);
      }

      return activeFilters;
    });

    // lifecycle hooks
    onBeforeRouteUpdate(async (to, from, next) => {
      await listService.search(
        filterFromQueryString(to.query),
        sortingFromQueryString(to.query),
        pagingFromQueryString(to.query),
      );

      next();
    });

    onMounted(async () => {
      if (!route.query.isDryRun) {
        router.replace({
          query: {
            ...route.query,
            isDryRun: 'false',
          },
        });
      }

      await listService.init();
      await listService.search(
        filterFromQueryString(route.query),
        sortingFromQueryString(route.query),
        pagingFromQueryString(route.query),
      );
    });

    const search = (filterquery: Dictionary<string | (string | null)[]>): void => {
      const { pageNumber, pageSize, ...query } = filterquery;

      router.push({
        path: route.path,
        query,
      });
    };

    const onHeaderClick = (product: ProductSearchResult): void => {
      router.push({ name: 'product.detail', params: { id: product.id } });
    };

    const clearFilters = (): void => {
      search({});
    };

    const specificationChanged = (value?: number): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, specification: encodeURIComponent('' + value) };
      } else {
        delete query.specification;
      }

      search(query);
    };

    const startExecutionFromChanged = (value?: string[]): void => {
      let query = { ...route.query };

      if (value && value.length > 0) {
        query = { ...query, startExecutionFrom: encodeURIComponent('' + parseFormattedDateString(value[0])?.toJSON()) };
      } else {
        delete query.startExecutionFrom;
      }

      search(query);
    };

    const startExecutionToChanged = (value?: string[]): void => {
      let query = { ...route.query };

      if (value && value.length > 0) {
        query = { ...query, startExecutionTo: encodeURIComponent('' + parseFormattedDateString(value[0])?.toJSON()) };
      } else {
        delete query.startExecutionTo;
      }

      search(query);
    };

    const statusChanged = (value?: number): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, status: encodeURIComponent('' + value) };
      } else {
        delete query.status;
      }

      search(query);
    };

    const isDryRunChanged = (value?: boolean): void => {
      let query = { ...route.query };

      if (value !== undefined) {
        query = { ...query, isDryRun: encodeURIComponent('' + value) };
      } else {
        delete query.isDryRun;
      }

      search(query);
    };

    const next = (): void => {
      const newPage = currentPage.value + 1;
      if (newPage <= totalPages.value) {
        page(newPage);
      }
    };

    const previous = (): void => {
      const newPage = currentPage.value - 1;
      if (newPage > 0) {
        page(newPage);
      }
    };

    const page = (pageNumber: number | string): void => {
      let query = { ...route.query };

      query = { ...query, pageNumber: encodeURIComponent('' + pageNumber) };

      router.push({
        path: route.path,
        query,
      });
    };

    return {
      ...toRefs(data),
      products,
      specifications,
      statusses,
      filter,
      paging,
      sorting,
      totalcount,
      currentPage,
      totalPages,
      itemsFrom,
      itemsTo,
      itemsTotalcount,
      activeFilters,
      dateTimeFilter,
      search,
      clearFilters,
      specificationChanged,
      startExecutionFromChanged,
      startExecutionToChanged,
      statusChanged,
      isDryRunChanged,
      next,
      previous,
      page,
      getDates,
      getDefinitionLabel,
      onHeaderClick,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 30px;
}

thead th {
  white-space: wrap;
}

tbody td {
  cursor: default;
}
</style>
