<template>
  <div v-if="showHeader" class="pbs-environment-header" :class="environment">
    {{ title }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { config } from '@/common/config/config';

const title = computed(() => {
  return `${environmentLabel.value} ${versionNumber.value}`;
});

const environment = computed(() => {
  return config.environment;
});

const versionNumber = computed(() => {
  return config.version;
});

const environmentLabel = computed(() => {
  switch (environment.value) {
    case 'local':
      return 'Local';
    case 'dev':
      return 'Dev';
    case 'tst':
      return 'Test';
    default:
      return null;
  }
});

const showHeader = computed(() => {
  return !!environmentLabel.value;
});
</script>

<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-environment-header {
  padding: 0px 10px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 2px 10px 3px;
  text-transform: uppercase;
  line-height: 1;
  text-align: left;

  &.local {
    background-color: #f44336;
  }

  &.dev {
    background-color: $vl-science-blue;
  }

  &.tst {
    background-color: #ff9800;
  }
}
</style>
