import { defineStore } from 'pinia';

import { clients, ProductProcessDefinition } from '@/api';

export type ProductProcessDefinitionStoreActions = {
    fetchDefinitions: () => Promise<void>;
};

export type ProductProcessDefinitionStoreState = {
    definitions: ProductProcessDefinition[];
};

export const useProductProcessDefinitionStore = defineStore<
    string,
    ProductProcessDefinitionStoreState,
    {},
    ProductProcessDefinitionStoreActions
>('product-process-definition-store', {
    state: () => ({
        definitions: [] as ProductProcessDefinition[],
    }),
    actions: {
        async fetchDefinitions(): Promise<void> {
            const definitions = await clients.ProductProcessDefinitionClient.getAll();
            this.definitions = definitions;
        },
    },
});

export interface IProductProcessDefinitionStore
    extends ProductProcessDefinitionStoreState,
        ProductProcessDefinitionStoreActions {}
