import Vue, { reactive } from 'vue';

import {
    ApiException,
    clients,
    ProductRun,
    ProductProcessDefinition,
    IProductRunClient,
    Metadata,
} from '@/api';
import { stores } from '@/store';
import { IProductProcessDefinitionStore } from '@/store/productProcessDefinition.store';

export function getDate(value: Date): Date {
    value && value.setHours(0, 0, 0, 0);
    return value;
}

export interface CreateProduct {
    isFirstTime?: boolean;
    definition?: ProductProcessDefinition;
    metadata?: Metadata;
}

export class ProductCreateService {
    private _processProduct: ProductRun | undefined;

    public state = reactive({ product: {},loading: false });

    constructor(
        private productClient: IProductRunClient,
        private definitionStore: () => IProductProcessDefinitionStore
    ) {}

    get definitions(): ProductProcessDefinition[] {
        return this.definitionStore().definitions;
    }

    get product(): CreateProduct {
        return this.state.product;
    }

    get loading(): boolean {
        return this.state.loading;
    }

    get productCreated(): boolean {
        return !!this._processProduct;
    }

    setFirstTime(isFirstTime: boolean): void {
        this.state.product = { ...this.product, isFirstTime };
    }

    setDefinition(definitionId?: number): void {
        this.state.product = {
            ...this.product,
            definition: this.definitions.find((p) => p.id == definitionId) || undefined,
        };
    }
    setMetadata(metadata: Metadata): void {
        this.state.product = { ...this.product, metadata };
    }

    setProduct(payload: CreateProduct): void {
        this.setDefinition(payload.definition?.id);
        this.setFirstTime(payload.isFirstTime);
        this.setMetadata(payload.metadata);
    }

    async create(product: CreateProduct): Promise<ProductRun> {
        this.setProduct(product);
        this.state.loading = true;
        try {
            this._processProduct = await this.productClient.createProduct({
                definitionId: this.product.definition?.id,
                isFirstTime: this.product.isFirstTime,
                metadata: this.product.metadata
            });
            this.state.loading = false;
            return this._processProduct;
        } catch (e) {
            this.state.loading = false;
            throw e;
        } finally {
            this.clearState();
        }
    }

    async cancel(): Promise<void> {
        if (this._processProduct?.id) {
            return await this.productClient.cancel(this._processProduct?.id);
        }
        return;
    }

    async init(): Promise<void> {
        this._processProduct = undefined;
        await this.definitionStore().fetchDefinitions();
        this.state.product = {
            versionDate: getDate(new Date()),
            temporalLimitationFrom: getDate(new Date()),
        };
    }

    clearState() {
        this.state = { product: {}, loading: false };
    }
}

export const ProductCreateServiceInstance = new ProductCreateService(
    clients.ProductRunClient,
    stores.ProductProcessDefinitionStore
);
