<template>
  <vl-region>
    <vl-layout>
      <vl-grid>
        <vl-column>
          <vl-title tag-name="h1">{{ $t('product.create.title') }}</vl-title>
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <vl-alert
            v-if="data.alert.visible"
            v-scroll="data.alert.scrollTo"
            class="vl-u-spacer--small"
            :icon="data.alert.icon"
            :mod-error="data.alert.isError"
            :mod-success="data.alert.isSuccess"
            closable
            :close-text="$t('product.detail.alert.closeText')"
            :title="data.alert.title"
            :content="data.alert.content"
            @close="closeAlert"
          />
        </vl-column>
        <vl-column width="7" width-m="9" with-s="12">
          <form @submit.prevent="onSubmit">
            <vl-grid mod-stacked>
              <vl-column>
                <vl-grid>
                  <vl-form-column>
                    <vl-title tag-name="h2">
                      {{ $t('product.create.Details.title') }}
                    </vl-title>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.small>
                    <PbsSelectField
                      name="definition"
                      :label="t('product.create.definition.label')"
                      :placeholder="$t('product.create.definition.label')"
                      :options="definitions"
                      :custom-label="getDefinitionLabel"
                    />
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <Field v-slot="{ handleChange, value }" name="isFirstTime">
                      <vl-checkbox
                        id="isFirstTime"
                        name="isFirstTime"
                        :value="value"
                        @input="(evt) => onCheckboxChecked(evt, handleChange)"
                        >{{ $t('product.create.isFirstTime.description') }}&nbsp;
                        <strong> {{ $t('product.create.isFirstTime.label') }} </strong>
                      </vl-checkbox>
                    </Field>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <vl-grid>
                  <vl-form-column v-vl-spacer:bottom.small>
                    <vl-title tag-name="h2">{{ $t('product.create.productDetails.title') }}</vl-title>
                    <vl-annotation>{{ $t('product.create.productDetails.help') }}</vl-annotation>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <vl-form-message-label for="versionDate">
                      {{ $t('delivery.create.versionDate.label') }}
                      <vl-form-message-annotation style="">
                        {{ $t('delivery.create.versionDate.help') }}
                      </vl-form-message-annotation>
                    </vl-form-message-label>
                    <vl-grid>
                      <vl-column width="6">
                        <Field v-slot="{ handleChange, errors, value }" name="versionDate">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <!-- https://bitbucket.org/vlaamseoverheid/vl-build/src/dev/package/vl-ui-datepicker/src/vue/vl-datepicker.js -->
                          <pbs-datepicker
                            id="versionDate"
                            name="versionDate"
                            :mod-error="!!errors.length"
                            :value="value"
                            :max-date="currentDate"
                            @input="onDatepickerInput(handleChange)($event)"
                          >
                          </pbs-datepicker>
                        </Field>
                      </vl-column>
                    </vl-grid>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <vl-form-message-label
                      >{{ $t('delivery.create.temporalLimitation.label') }}
                      <vl-form-message-annotation>{{
                        $t('delivery.create.temporalLimitation.help')
                      }}</vl-form-message-annotation>
                    </vl-form-message-label>
                    <vl-grid>
                      <vl-form-column width="4" widht-m="6" width-s="12">
                        <vl-form-message-label for="temporalLimitationFrom">
                          {{ $t('delivery.create.temporalLimitation.from.label') }}
                        </vl-form-message-label>
                        <Field v-slot="{ handleChange, errors, value }" name="temporalLimitationFrom">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="temporalLimitationFrom"
                            :value="value"
                            :mod-error="!!errors.length"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-form-column>
                      <vl-form-column width="4" widht-m="6" width-s="12">
                        <vl-form-message-label for="temporalLimitationTo">
                          {{ $t('delivery.create.temporalLimitation.to.label') }}
                          <vl-form-message-annotation tag-name="span"
                            >({{ $t('general.optional') }})</vl-form-message-annotation>
                        </vl-form-message-label>
                        <Field v-slot="{ handleChange, errors, value }" name="temporalLimitationTo">
                          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                          <pbs-datepicker
                            name="temporalLimitationTo"
                            :value="value"
                            :min-date="values.temporalLimitationFrom"
                            :mod-error="!!errors.length"
                            @input="onDatepickerInput(handleChange)($event)"
                          ></pbs-datepicker>
                        </Field>
                      </vl-form-column>
                    </vl-grid>
                  </vl-form-column>
                  <vl-form-column v-vl-spacer:bottom.medium>
                    <vl-form-message-label for="VersionDescription"
                      >{{ $t('delivery.create.description.label') }}
                      <vl-form-message-annotation>{{
                        $t('delivery.create.description.help')
                      }}</vl-form-message-annotation>
                    </vl-form-message-label>
                    <Field v-slot="{ handleChange, value, errors }" name="versionDescription">
                      <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
                      <vl-textarea
                        id="VersionDescription"
                        name="VersionDescription"
                        :placeholder="$t('delivery.create.description.placeholder')"
                        rows="6"
                        :modelValue="value"
                        :mod-error="!!errors.length"
                        mod-block
                        @input="handleChange"
                      ></vl-textarea>
                    </Field>
                  </vl-form-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <div class="vl-u-spacer"></div>
                <vl-action-group>
                  <vl-button type="submit" :mod-loading="loading">{{ $t('product.create.confirm.label') }}</vl-button>
                  <vl-button type="button" mod-secondary @click="cancel">{{
                    $t('product.create.cancel.label')
                  }}</vl-button>
                </vl-action-group>
              </vl-column>
            </vl-grid>
          </form>
        </vl-column>
      </vl-grid>
    </vl-layout>
  </vl-region>
</template>

<script lang="ts" setup>
import { reactive, onMounted, computed, watch, onUnmounted } from 'vue';
import { ProductCreateServiceInstance, CreateProduct } from './ProductCreate.service';
import { useRouter } from 'vue-router';
import { Form, Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { parseFormattedDateString, yupTransformDate } from '@/helpers';
import { useI18n } from 'vue-i18n';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import { format } from 'date-fns';
import PbsDatepicker from '@/components/forms/PbsDatepicker.vue';
import { getDefinitionLabel } from './common';
import { Metadata} from '@/api';

const router = useRouter();

const data = reactive({
  productService: ProductCreateServiceInstance,
  alert: {
    visible: false,
    isError: false,
    isSuccess: false,
    scrollTo: false,
    title: '',
    content: '',
    icon: '',
  },
});

const { t } = useI18n();

const translations = {
  definition: {
    required: t('validations.messages.required', {
      _field_: t('product.create.definition.label'),
    }),
    oneOf: t('validations.messages.oneOf', {
      _field_: t('product.create.definition.label'),
    }),
  },
  versionDate: {
    required: t('validations.messages.required', {
      _field_: t('product.create.versionDate.label'),
    }),
  },
  temporalLimitationFrom: {
    required: t('validations.messages.required', {
      _field_: t('product.create.temporalLimitation.from.label'),
    }),
  },
  versionDescription: {
    required: t('validations.messages.required', {
      _field_: t('product.create.description.label'),
    }),
  },
  form: {
    failed: t('product.create.failed'),
  },
};

const validationSchema = yup.object({
  definition: yup.object().required(translations.definition.required),
  isFirstTime: yup.boolean(),
  metadata: yup.array().of(
    yup
      .object()
      .shape({
        versionDate: yup.date().transform(yupTransformDate).required(translations.versionDate.required),
        temporalLimitationFrom: yup
          .date()
          .transform(yupTransformDate)
          .required(translations.temporalLimitationFrom.required),
        temporalLimitationTo: yup.date().nullable().transform(yupTransformDate),
        versionDescription: yup
          .string()
          .required(translations.versionDescription.required)
          .max(
            100,
            t('validations.messages.maxChar', { _field_: t('delivery.create.description.label'), length: 100 }),
          ),
        specification: yup.object().shape({
          id: yup.number().required(),
          label: yup.string().required(),
        }),
      })
      .strict()
      .required(),
  ),
});

const { resetForm, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const product = computed(() => data.productService.product);
const definitions = computed(() => data.productService.definitions?.filter((d) => [0, 1].includes(d.statusId)));
const loading = computed(() => data.productService.loading);

const onCheckboxChecked = (evt, handleChange) => {
  handleChange(evt.target.checked);
};

onMounted(async () => {
  await ProductCreateServiceInstance.init();
  resetForm({ values: product.value });
});

onUnmounted(async () => {
  await ProductCreateServiceInstance.clearState();
});

const onSuccess = async (values) => {
  let metadata: Metadata = {
    versionDate: parseFormattedDateString(values.versionDate),
    temporalLimitationFrom: parseFormattedDateString(values.temporalLimitationFrom),
    temporalLimitationTo: parseFormattedDateString(values.temporalLimitationTo),
    versionDescription: values.versionDescription,
  };

  const payload: CreateProduct = {
    definition: definitions.value.find((p) => p.id == values.definition?.id),
    isFirstTime: values.isFirstTime,
    metadata: metadata
  };

  await create(payload);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const currentDate = computed(() => format(new Date(), 'dd.MM.yyyy'));

const onDatepickerInput = (handleChange) => (value) => {
  handleChange(Array.isArray(value) ? value[0] : value);
};

const create = async (product: CreateProduct) => {
  try {
    closeAlert();
    const response = await data.productService.create(product);
    router.push({ name: 'product.detail', params: { id: `${response.id}` }, query: { created: 'true' } });
  } catch (e: any) {
    const { detail, title } = parseException(e);
    alertError(detail, title);
  }
};

const parseException = (e: any) => {
  let title = translations.form.failed;
  let detail = '';

  if (e.status === 400) {
    const errors = e.errors;
    if (errors && Array.isArray(errors) && errors.length > 0) {
      if (errors[0].detail) {
        detail = errors.map((err) => err.detail).join(',');
      } else {
        detail = errors.join(',');
      }
    } else if (e.message) {
      detail = e.message;
    }
  }

  return {title, detail };
};

const alertError = (message: string, title: string) => {
  data.alert = {
    visible: true,
    isError: true,
    isSuccess: false,
    scrollTo: true,
    icon: 'alert-circle',
    title: title,
    content: message,
  };
};

const closeAlert = () => {
  data.alert = {
    visible: false,
    isError: false,
    isSuccess: false,
    scrollTo: false,
    icon: '',
    title: '',
    content: '',
  };
};

const cancel = () => {
  data.productService.cancel();
  // Go back in history
  router.go(-1);
};
</script>