<template>
  <form @submit="onSubmit">
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="code"
        :label="t('productentity.detail.code')"
        :placeholder="t('productentity.detail.code')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('productentity.detail.label')"
        :placeholder="t('productentity.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('productentity.detail.description')"
        :placeholder="t('productentity.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="model"
        :label="t('productentity.detail.model')"
        :placeholder="$t('productentity.detail.model')"
        :options="entityModels"
        :custom-label="entityModelLabel"
        :mod-loading="fetchingModels"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="crs"
        :label="t('productentity.detail.crs')"
        :placeholder="$t('productentity.detail.crs')"
        :options="sridTypes"
        :custom-label="(e) => e.name"
      />
    </vl-form-column>
    <vl-action-group v-vl-spacer:bottom.small>
      <vl-button icon="save" :mod-loading="reloading" mod-icon-before type="submit">
        {{ $t('general.save') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeCreate">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { SearchEntityModelDto, EnumerationDto } from '@/api/portal-api/clients';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useI18n } from 'vue-i18n';
import { useProductEntityStore } from '@/modules/configuration/store/product-entity/product-entity.store';
import { useEntityModelSelectStore } from '@/modules/configuration/store/entity-model/entity-model-select.store';
import { useProductProcessDefinitionEditStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { ENUM_SridTypes } from '@/modules/configuration/common';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import { ENUM_StatusTypes } from '../../../../common';

const { t } = useI18n();

const productEntityStore = useProductEntityStore();
const entityModelSelectStore = useEntityModelSelectStore();
const editStore = useProductProcessDefinitionEditStore();

const props = defineProps({
  specificationId: {
    type: Number,
    required: true,
  },
});

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('productentity.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('productentity.detail.label'), length: 256 })),
  code: string()
    .required(t('validations.messages.required', { _field_: t('productentity.detail.code') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('productentity.detail.code'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('productentity.detail.description') }),
  ),
  crs: object().required(t('validations.messages.required', { _field_: t('productentity.detail.crs') })),
  model: object().required(t('validations.messages.required', { _field_: t('productentity.detail.model') })),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const reloading = computed((): boolean => {
  return productEntityStore.reloading;
});

const sridTypes = computed((): EnumerationDto[] => {
  return ENUM_SridTypes;
});

const fetchingModels = computed((): Boolean => {
  return entityModelSelectStore.loading;
});

const entityModels = computed((): SearchEntityModelDto[] => {
  return entityModelSelectStore.items ?? [];
});

onMounted(async () => {
  await entityModelSelectStore.getModels({ force: true, contextTypeName: 'Product' });
  resetForm();
});

const onSuccess = async (values) => {
  const createDto = {
    productSpecificationId: props.specificationId,
    label: values.label,
    code: values.code,
    description: values.description,
    modelId: values.model.id,
    crs: values.crs.name,
  };

  await productEntityStore.create(props.specificationId, createDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const entityModelLabel = (entityModel: SearchEntityModelDto) => {
  let status = '';

  if(entityModel.statusId !== ENUM_StatusTypes.find((x) => x.name === 'Actief')?.id) {
    status = ` [${ENUM_StatusTypes.find((x) => x.id === entityModel.statusId)?.name}]`;
  }

  return `[${entityModel.id}] ${entityModel.code} (${entityModel.label})${status}`;
};

defineExpose({ onSubmit });
</script>
